<template>
  <section class="write-to-us">
    <div class="container">
      <h3>{{ $t("writeToUs.title") }}</h3>
      <HomeWriteToUsAppSendForm />
    </div>
  </section>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.write-to-us {
  padding: 160px 0 65px;
  background-color: var(--white-light);
  background-image: url("/img/form-bg.png");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  .container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;

    > * {
      flex: 1;
    }
  }

  h3 {
    margin: 0 0 110px 35px;
    font-size: 44px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.36;
    color: var(--white);
  }

  @media screen and (max-width: 1024px) {
    padding: 100px 0 45px;

    h3 {
      margin: 0 0 50px 0;
      font-size: 32px;
      color: var(--black);
    }

    .container {
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
