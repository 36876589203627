<template>
  <section class="homepage">
    <MainBanner />

    <HomeHowItWork />
    <WhatWeOffer />
    <div class="home-showcase container">
      <Transition
        :duration="300"
        name="fade"
        mode="out-in"
      >
        <keep-alive>
          <component :is="activeShowcaseView === 'countries'? ShowcaseCountries : ShowcaseServices" />
        </keep-alive>
      </Transition>
    </div>
    <HomeCardSection id="earnMoney" />
    <HomeWriteToUs />
  </section>
</template>

<script setup lang="ts">
import { useChangeShowcaseView } from '#imports';

const ShowcaseCountries = defineAsyncComponent(() => import('~/components/home/showcase/showcase-countries/ShowcaseCountries.vue'));
const ShowcaseServices = defineAsyncComponent(() => import('~/components/home/showcase/showcase-services/ShowcaseServices.vue'));
const WhatWeOffer = defineAsyncComponent(() => import('~/components/home/what-we-offer/WhatWeOffer.vue'));
const MainBanner = defineAsyncComponent(() => import('~/components/home/main-banner/MainBanner.vue'));

const { activeShowcaseView } = useChangeShowcaseView();

</script>

<style lang="scss" scoped>

.homepage {
	display: flex;
	flex-direction: column;
	gap: 140px;

	&:deep(h2) {
		margin-bottom: 120px;
		text-align: center;
	}
}
@media screen and (max-width: 1024px) {
	.homepage {
    &:deep(h2) {
      font-size: 35px;
    }
  }
}
@media screen and (max-width: 859px) {
	.homepage {
    &:deep(h2) {
      margin-bottom: 48px;
      font-size: 35px;
    }
  }
}
</style>
<style lang="scss">
.refresh-icon {
	position: absolute;
	padding: 6px 12px;
	border-radius: 8px;
	background: var(--primary-90);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	cursor: pointer;
	box-shadow: 0px 1px 2px 0px rgba(26, 56, 116, 0.1),
	0px 4px 4px 0px rgba(26, 56, 116, 0.09),
	0px 9px 6px 0px rgba(26, 56, 116, 0.05),
	0px 17px 7px 0px rgba(26, 56, 116, 0.01),
	0px 26px 7px 0px rgba(26, 56, 116, 0);

  @media screen and (max-width: 1024px) {
    transform: translate(-50%, -74%);
  }
}

.home-showcase {
	height: 660px;
	display: flex;
	background: var(--white);
	width: 100%;
	border-radius: var(--main-border-radius);

	padding: 0;

  @media screen and (max-width: 640px) {
    display: none;
  }
}
</style>
