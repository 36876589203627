<template>
  <div class="form-checkbox">
    <input
      :id="name"
      v-model="model"
      type="checkbox"
      :name="name"
      :value="value"
    />
    <label for="checkbox">{{ label }}</label>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
	name: string;
	label: string;
	modelValue: boolean;
	value: boolean;
}>();

const emit = defineEmits(['update:modelValue']);
const model = computed({
  get () {
    return props.modelValue;
  },
  set (value) {
    emit('update:modelValue', value);
  }
});
</script>

<style scoped></style>
